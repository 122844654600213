import {escapeQuotes} from "../util/stringUtils";


type RegistrationFormData = {
    schoolId: number,
    courseId:number,
    cohortId:number,
    firstname:string,
    lastname:string,
    phone:string,
    email:string,
    street:string,
    streetExtra:string,
    city:string,
    state:string,
    zip:string,
    country:string,
    questions:RegistrationQuestion[],
    activeFormPage:number,
    activeFormQuestion:number
}
type RegistrationQuestion = {
    schoolId:number,
    questionId:number,
    hasOptions:boolean,
    question:string,
    answerOptions:string[],
    answer:string
}

type Country = {
    name:string,
    commonName:string
}

let registrationFormData:RegistrationFormData = {
    schoolId: 0,
    courseId:0,
    cohortId:0,
    firstname:'',
    lastname:'',
    phone:'',
    email:'',
    street:'',
    streetExtra:'',
    city:'',
    state:'',
    zip:'',
    country:'',
    questions:[],
    activeFormPage:0,
    activeFormQuestion:0,
}

let dropdownActive = false;
function toggleDropdown(evt:Event){
    dropdownActive = true;
    evt.preventDefault();
    evt.stopPropagation();
    const dropdownSelector = evt.target as HTMLButtonElement;
    let dropdownBtn;
    if(dropdownSelector.tagName == "SPAN"){
        dropdownBtn = dropdownSelector.parentNode;
    }
    else if(dropdownSelector.tagName == "I") {
        dropdownBtn = dropdownSelector.parentElement;
        dropdownBtn = dropdownBtn?.parentElement;
    }
    else {
        dropdownBtn = dropdownSelector;
    }
    // @ts-ignore
    const targetMenuName = dropdownBtn?.dataset.id as string;
    const targetMenu = document.querySelector(`#${targetMenuName}`) as HTMLDivElement;
    if(targetMenu.classList.contains('is-active')){
        //close
        targetMenu.classList.remove("is-active");
        const icon = targetMenu.querySelector("i");
        icon?.classList.remove("fa-angle-up");
        icon?.classList.add("fa-angle-down");
    }
    else{
        //open
        targetMenu.classList.add("is-active");
        const icon = targetMenu.querySelector("i");
        icon?.classList.add("fa-angle-up");
        icon?.classList.remove("fa-angle-down");
    }
}
function closeAllDropdowns(){
    const dropdownTargetsList = document.querySelectorAll('.school-dropdown');
    dropdownTargetsList.forEach((d)=>{
        d.classList.remove("is-active");
        const icon = d.querySelector("i");
        icon?.classList.remove("fa-angle-up");
        icon?.classList.add("fa-angle-down");
    })
}
function clearSelectors(includeCourseRest:boolean){
    const selectedCourse = document.querySelector("#selected-course");
    const selectedCourseMenu = document.querySelector("#course-select-menu-content > .dropdown-content");
    const selectedCohort = document.querySelector("#selected-cohort");
    const selectedCohortMenu = document.querySelector("#cohort-select-menu-content > .dropdown-content");
    if(selectedCourse && includeCourseRest) {
        selectedCourse.innerHTML = "Select a Course";
        if (selectedCourseMenu) selectedCourseMenu.innerHTML = "";
    }
    if(selectedCohort) {
        selectedCohort.innerHTML = "Select a Cohort";
        if (selectedCohortMenu) selectedCohortMenu.innerHTML = "";
    }
}
function dropdownItemSelect(evt:Event){
    closeAllDropdowns();
    const selection = evt.target as HTMLButtonElement;
    const title = document.querySelector(`#${selection.getAttribute("data-select-title")}`);
    const propertyKey = selection.getAttribute("data-item-key");
    const propertyValue = selection.getAttribute("data-value");
    if(title)
        title.innerHTML = selection.innerHTML;
    registrationFormData = {
        ...registrationFormData,
        [propertyKey ?? ""]: propertyValue ?? ""
    }
    if(propertyKey?.includes("school")){
        clearSelectors(true);
        //load courses
        fetch(`/registration/api/courses/${propertyValue}`).then((response)=>{
            response.json().then((data)=>{
                const courseDropdown = document.querySelector("#course-field");
                if(courseDropdown)
                    courseDropdown.classList.remove("hide");
                const listItems = data.map((c: { id: number; name: string; })=>{
                    return `<p data-select-title="selected-course" data-item-key="courseId" data-value="${c.id}" 
                                    class="dropdown-item dropdown-selection">${c.name}</p>`;
                })
                const dropdownList = courseDropdown?.querySelector(".dropdown-content");
                if(dropdownList)
                    dropdownList.innerHTML = listItems.join("");
                const insertedItems = dropdownList?.querySelectorAll("p");
                insertedItems?.forEach((p)=>{
                    p.addEventListener("click", dropdownItemSelect)
                })
                const cohortField = document.getElementById("cohort-field");
                if(data.hasCohorts){
                    cohortField?.classList.remove("hide");
                }
                else{
                    cohortField?.classList.add("hide");
                }
                const studentInfoContainer = document.querySelector("#student-info");
                if(studentInfoContainer) studentInfoContainer.classList.add("hide");
            })
        })
    }
    else if(propertyKey?.includes("course")){
        //load cohorts
        clearSelectors(false);
        fetch(`/registration/api/courses/${registrationFormData.schoolId}/cohorts/${propertyValue}`).then((response)=>{
            response.json().then((data)=>{
                if(data.length > 0){
                    const cohortDropdown = document.querySelector("#cohort-field");
                    if(cohortDropdown)
                        cohortDropdown.classList.remove('hide');
                    const listItems = data.map((c: { id: number; name: string; })=>{
                        return `<p data-select-title="selected-cohort" data-item-key="cohortId" data-value="${c.id}" 
                                    class="dropdown-item dropdown-selection">${c.name}</p>`;
                    })
                    const dropdownList = cohortDropdown?.querySelector(".dropdown-content");
                    if(dropdownList)
                        dropdownList.innerHTML = listItems.join("");
                    const insertedItems = dropdownList?.querySelectorAll("p");
                    insertedItems?.forEach((p)=>{
                        p.addEventListener("click", dropdownItemSelect)
                    })    
                }
                else{
                    //show student info
                    const studentInfoContainer = document.querySelector("#student-info");
                    studentInfoContainer?.classList.remove('hide');   
                }
                
            })
        })
        
    }
    else if(propertyKey?.includes("cohort")) {
        //show student info
        const studentInfoContainer = document.querySelector("#student-info");
        studentInfoContainer?.classList.remove('hide');
    }
}

const dropdownBtns: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".school-dropdown .dropdown-trigger button");

function bindDropdownSelectors(){
    const selections:NodeListOf<HTMLParagraphElement> = document.querySelectorAll(".dropdown-selection");
    selections.forEach((s)=>{
        s.addEventListener("click", dropdownItemSelect);
    })

}
if(window.location.pathname.includes("registration")){
    dropdownBtns.forEach((schoolBtn)=>{
        schoolBtn.addEventListener("click", toggleDropdown);
    })
function updateForm(evt:SubmitEvent){
    const form = evt.target as HTMLFormElement    
    const inputs:NodeListOf<HTMLInputElement> = form.querySelectorAll('input');
    inputs.forEach((inp)=>{
        const key:string = inp.getAttribute("data-item-key") ?? "";
        const value = inp.value;
        registrationFormData = {
            ...registrationFormData,
            [key]:value
        }    
    })
    
}
    const regForm1 = document.getElementById('registration-step-1');
    const regForm2 = document.getElementById('registration-step-2');
    const regForm3 = document.getElementById('registration-step-3');

    regForm1?.addEventListener("submit", (evt:SubmitEvent)=>{
        evt.preventDefault();
        updateForm(evt);
        showAddressPage();
    })
    regForm2?.addEventListener("submit", async (evt:SubmitEvent)=>{
        evt.preventDefault();
        updateForm(evt);
        if(registrationFormData.questions.length > 0){
            showQuestionsPage();    
        }
        else{
            submitApplication();
        }
        
    })

    regForm3?.addEventListener("submit", (evt:SubmitEvent)=> {
        evt.preventDefault();
        updateForm(evt);
        if (registrationFormData.activeFormQuestion < registrationFormData.questions.length - 1) {
            registrationFormData.activeFormQuestion = registrationFormData.activeFormQuestion + 1;
            showQuestionsPage();
        } else {
            submitApplication();
        }
    });

    const prevBtns = document.querySelectorAll('.prev-btn');

    prevBtns.forEach((btn)=>{
        btn.addEventListener("click", ()=>{
            regForm1?.classList.add('hide');
            regForm2?.classList.add('hide');
            regForm3?.classList.add('hide');
            switch(registrationFormData.activeFormPage){
                case 2:
                    showMainPage();
                    break;
                case 3:
                    if(registrationFormData.activeFormQuestion == 0) {
                        showAddressPage();
                    }else{
                        registrationFormData.activeFormQuestion--;
                        showQuestionsPage();
                    }
                    break;
            }
        })
    })

    async function getQuestions(){
        const response = await fetch(`/registration/api/application/questions/${registrationFormData.schoolId}`);
        const data:RegistrationQuestion[] = await response.json();
        registrationFormData.questions = [...data];
    }
    function showMainPage(){
        regForm2?.classList.add('hide');
        const studentInfo = document.getElementById('student-info');
        studentInfo?.classList.remove('hide');
        regForm1?.classList.remove('hide');
    }
    async function showAddressPage(){
        const nextBtn = document.querySelector("#nextBtn");
        loadCountryDropdown();
        const regForm1 = document.getElementById('registration-step-1');
        regForm1?.classList.add('hide');
        await getQuestions();
        if(registrationFormData.questions.length > 0){
            nextBtn?.classList.remove('hide');
        }
        else{
            const submitAppBtn = document.querySelector("#submitAppBtn");
            submitAppBtn?.classList.remove('hide');
            nextBtn?.classList.add('hide');
        }
        const regForm2 = document.getElementById('registration-step-2');
        regForm2?.classList.remove('hide');
        registrationFormData.activeFormPage = 2;
    }
    function showQuestionsPage(){
        const regForm2 = document.getElementById('registration-step-2');
        regForm2?.classList.add('hide');
        const data = registrationFormData.questions;
        const questionTitle = document.querySelector("#question-title");
        const questionLabel = document.querySelector("#question-label");
        const answerContainer = document.querySelector("#answer");
        const currentQuestion = data[registrationFormData.activeFormQuestion];
        const nextBtn = document.querySelector( "#nextBtn2");
        const submitAppBtn = document.querySelector("#submitAppBtn2");

        questionTitle != null ? questionTitle.innerHTML = `Question ${registrationFormData.activeFormQuestion + 1} of 
    ${data.length}` : "";
        if(questionLabel)
            questionLabel.innerHTML = currentQuestion.question;
        if(currentQuestion.answerOptions.length > 0 && answerContainer) {
            const radioBtns = currentQuestion.answerOptions.map((answer, index) => {
                return `<p class="pt-2"><input class="question-input" type="radio" data-question-id="${currentQuestion.questionId}" value='${escapeQuotes(answer)}' name='question-id-${currentQuestion.questionId}' />
                    <span class="pl-1">${escapeQuotes(answer)}</span></p>
                    `;
            });
            answerContainer.innerHTML = radioBtns.join("");
        }
        else{
            answerContainer != null ? answerContainer.innerHTML = `<input type="text" class="input question-input" data-question-id="${currentQuestion.questionId}" name="question-id-${currentQuestion.questionId}" />` : "";
        }
        if((registrationFormData.activeFormQuestion + 1) < data.length){
            nextBtn?.classList.remove('hide');
            submitAppBtn?.classList.add('hide');
        }
        else{
            submitAppBtn?.classList.remove('hide');
            nextBtn?.classList.add('hide');
        }
        bindQuestionEvents();
        const regForm3 = document.getElementById('registration-step-3');
        regForm3?.classList.remove('hide');
        registrationFormData.activeFormPage = 3;

    }

    

    // const formInputs:NodeListOf<HTMLInputElement> = document.querySelectorAll('input');
    // formInputs.forEach((inp)=>{
    //     inp.addEventListener("keyup", updateForm);
    //     inp.addEventListener("blur", updateForm);
    //     function updateForm(){
    //         const key:string = inp.getAttribute("data-item-key") ?? "";
    //         const value = inp.value;
    //         registrationFormData = {
    //             ...registrationFormData,
    //             [key]:value
    //         }
    //     }
    // })
    function bindQuestionEvents(){
        const questionInputs:NodeListOf<HTMLInputElement> = document.querySelectorAll('.question-input');
        questionInputs.forEach((inp)=>{
            inp.addEventListener("change", (evt:Event)=> updateFormData());
            inp.addEventListener("keydown", (evt:Event)=>updateFormData());
            function updateFormData(){
                const key:string = inp.getAttribute("data-question-id") ?? "0";
                const questionId = parseInt(key);
                registrationFormData.questions.filter((q)=>q.questionId == questionId)[0].answer = inp.value;
            }
        })
    }
    async function loadCountryDropdown(){
        const countryDropdown = document.querySelector("#country-select");
        const data:Country[] = await getCountries();
        const list = data.map((c)=>{
            return `<option data-select-title="selected-country" data-item-key="country" data-value="${c.commonName}"
                               class="dropdown-item dropdown-selection">${c.commonName}</option>`
        });
        if(countryDropdown) {
            countryDropdown.innerHTML = list.join("");
            countryDropdown.addEventListener("change", dropdownItemSelect);
        }
        registrationFormData.country = "United States";
        bindDropdownSelectors();
    }
    async function getCountries():Promise<Country[]>{
        const response = await fetch("/registration/api/countries");
        return await response.json();
    }
    async function submitApplication(){
        const submitBtn: NodeListOf<HTMLInputElement> = document.querySelectorAll(".submit-app-btn");
        submitBtn.forEach((btn)=>btn.classList.add('is-loading'));
        const response = await fetch("/registration/api/submit", {
            headers: {
                "Content-Type": "application/json",
            },
            body:JSON.stringify(registrationFormData),
            method:"POST"});
        const regForm2 = document.getElementById('registration-step-2');
        regForm2?.classList.add("hide");
        const regForm3 = document.getElementById('registration-step-3');
        regForm3?.classList.add("hide");
        const registrationResult = document.getElementById('registration-result');
        const successText = document.getElementById("success");
        const failText = document.getElementById("fail");
        submitBtn.forEach((btn)=>btn.classList.remove('is-loading'))
        if(response.ok){
            if(registrationResult) {
                registrationResult.classList.remove('hide');
            }
        }
        else{
            if(registrationResult){
                
                registrationResult.classList.remove('hide');
                successText?.classList.add("hide");
                failText?.classList.remove("hide");
            }
        }
    }
    function updateFormWithPreselectedFields(){
        const schoolIdElement: HTMLSpanElement | null = document.querySelector("#selected-school");
        const courseIdElement: HTMLSpanElement | null = document.querySelector("#selected-course");
        const cohortIdElement:HTMLSpanElement | null = document.querySelector("#selected-cohort");

        if(schoolIdElement)
            registrationFormData.schoolId = parseInt(schoolIdElement.dataset.id ?? "0");
        if(courseIdElement)
            registrationFormData.courseId = parseInt(courseIdElement.dataset.id ?? "0");
        if(cohortIdElement)
            registrationFormData.cohortId = parseInt(cohortIdElement.dataset.id ?? "0");

    }
    async function temporary(){
        //go to page 3 for development
        registrationFormData.schoolId = 1;
        regForm1?.classList.add('hide');
        regForm2?.classList.remove('hide');
        showAddressPage();
        //await getQuestions();
        //showQuestionsPage();
    }

    bindDropdownSelectors();
    updateFormWithPreselectedFields();
    //temporary();
}



//on keydown add input value to formData
//load pre-filled cohort view
//submit
//Remove background from page title