const termsModal = document.getElementById('terms');
const termsBtn = document.getElementById('terms-button');
termsBtn?.addEventListener("click", (evt:Event)=>{
    evt.preventDefault();
    termsModal?.classList.add("is-active");
})
const modalCloseBtn = document.getElementById('modal-close');
modalCloseBtn?.addEventListener("click", (evt:Event)=>{
    termsModal?.classList.remove("is-active");
});

document.addEventListener("keyup", (evt:KeyboardEvent)=>{
    if(evt.key === "Escape")
        termsModal?.classList.remove("is-active")
});

let isAgreed = false;
let currentDirectorId = 0;
function toggleListingButton(status:boolean){
    const listingBtn = document.getElementById('directors-listing-button') as HTMLButtonElement;
    if(listingBtn)
        listingBtn.disabled = !status;
}

document.getElementById('agree-check')?.addEventListener("change", (evt:Event)=>{
    const input = evt.target as HTMLInputElement;
    toggleListingButton(input.checked);
    input.value = input.checked ? "true" : "false";
    input.setAttribute("aria-disabled", input.checked ? "true" : "false");
});

const bios: string[] = [];
const directorLinks = document.querySelectorAll(".director-bio-btn");
let currentBio: string;


const copyEmailBtn = document.getElementById('copyEmail');
const viewEmailBtn = document.getElementById('viewEmail');
copyEmailBtn?.addEventListener("click", async (evt)=> {
    const btnTarget = evt.target as HTMLElement;
    const btn = btnTarget.parentNode as HTMLButtonElement;
    btn.disabled = true;
    const label = btn.querySelector("label");
    const svg = btn.querySelector("svg");
    if(svg)
        svg.style.display = "none";
    if(label)
        label.innerHTML = "Retrieving email";
    try {
        const response = await fetch(`/directors/email/${currentDirectorId}`);
        if(response.ok){
            const data = await response.json();
            await navigator.clipboard.writeText(data.email);
            if(label)
                label.innerHTML = "Email copied to your clipboard";
        }
        else{
            if(label)
                label.innerHTML = "There was a problem, please refresh and try again."
        } 
    }
    catch (err){
        if(label)
            label.innerHTML = "There was a problem, please refresh and try again.";
        console.log(err);;
    }
    
})

viewEmailBtn?.addEventListener("click", async(evt)=>{
    const btnTarget = evt.target as HTMLElement;
    const btn = btnTarget.parentNode as HTMLButtonElement;
    btn.disabled = true;
    const label = btn.querySelector("label");
    const svg = btn.querySelector("svg");
    if(svg)
        svg.style.display = "none";
    if(label)
        label.innerHTML = "Retrieving email";
    try {
        const response = await fetch(`/directors/email/${currentDirectorId}`);
        if(response.ok){
            const data = await response.json();
            if(label)
                label.innerHTML = `<a href='mailto:${data.email}'>${data.email}</a>`;
        }
        else{
            if(label)
                label.innerHTML = "There was a problem, please refresh and try again."
        }
    }
    catch (err){
        if(label)
            label.innerHTML = "There was a problem, please refresh and try again.";
        console.log(err);
    }
})

    function showBio(id:string){

    const bioView = document.getElementById("bio-view");
    const userBox = document.getElementById(id);
    const btnEle = userBox?.querySelector("button");
    const nameEle = userBox?.querySelector(".title");
    const locationEle = userBox?.querySelector(".subtitle");
    const imageElement = userBox?.querySelector("img");
    const inputElement = userBox?.querySelector("input");
    const specialitiesEle = userBox?.querySelector(".specialties");
    const viewName = bioView?.querySelector("h3");
    const viewLocation = bioView?.querySelector(".location");
    const viewImage = bioView?.querySelector("img");
    const viewBio = bioView?.querySelector("#bio-text");
    const viewSpecialties = bioView?.querySelector(".specialties-list");
    currentDirectorId = parseInt(id);
        
    if(viewName)
        viewName.innerHTML = nameEle?.innerHTML ?? "";
    if(viewLocation)
        viewLocation.innerHTML = locationEle?.innerHTML ?? "";
    if(viewImage)
        viewImage.src = imageElement?.src ?? "";
    if(viewBio)
        viewBio.innerHTML = inputElement?.value ?? "";
    if(viewSpecialties) {
        const specialtyList = specialitiesEle?.innerHTML.split(",").map((s)=>{
            return `<span class="tag is-primary">${s}</span>`;
        })
        viewSpecialties.innerHTML = specialtyList?.join("") ?? "";
    }
    bioView?.classList.add('is-active');
   const closeModalBtn = document.querySelector("#modal-close") as HTMLButtonElement;
    closeModalBtn?.focus();
    currentBio = id;
    showCopyEmailBtn();
    showViewEmailBtn();
    function showCopyEmailBtn(){
        const copyEmailBtn = document.querySelector("#copyEmail") as HTMLButtonElement;
        if(copyEmailBtn) {
            copyEmailBtn.disabled = false;
            const svg = copyEmailBtn.querySelector("svg");
            const label = copyEmailBtn.querySelector("label");
            if(svg)
                svg.style.display = "block";
            if(label)
                label.innerHTML = "&nbsp;Copy Email"
        }
    } 
    function showViewEmailBtn(){
        const btn = document.querySelector("#viewEmail") as HTMLButtonElement;
        if(btn){
            btn.disabled = false;
            const label = btn.querySelector("label");
            const svg = btn.querySelector("svg");
            if(svg)
                svg.style.display = "block";
            if(label)
                label.innerHTML = "&nbsp;View Email";    
        }
        
    }
}

function getBio(evt:Event){
    const btnElement = evt.target as HTMLButtonElement;
    showBio(btnElement?.dataset.id ?? "1")
}

directorLinks.forEach((d)=>{
    const btn = d as HTMLButtonElement;
    d.addEventListener("click", getBio);
    bios.push(btn?.dataset.id ?? "");
});

document.getElementById("modal-close")?.addEventListener("click",
    ()=> document.getElementById("bio-view")?.classList.remove("is-active"));

function nextBio(){
    for(let i = 0; i < bios.length; i++){
        if(currentBio == bios[bios.length - 1]){
            showBio(bios[0]);
            break;
        }
        if(currentBio == bios[i]){
            showBio(bios[i + 1]);
            break;
        }
    }
}

function previousBio() {
    for(let i = 0; i < bios.length; i++){
        if(currentBio == bios[0]){
            showBio(bios[bios.length - 1]);
            break;
        }
        if(currentBio == bios[i]){
            showBio(bios[i - 1]);
            break;
        }
    }
}
document.getElementById('next')?.addEventListener("click", nextBio);
document.getElementById('previous')?.addEventListener("click", previousBio);

document.addEventListener("keydown", navigateBio);

function navigateBio(evt:KeyboardEvent){
    const bioView = document.getElementById('bio-view');
    if(bioView?.classList.contains("is-active")){
        if(evt.code === "ArrowRight") {
            nextBio();
        }
        else if(evt.code == "ArrowLeft"){
            previousBio();
        }
        else if(evt.code === "Escape"){
            bioView.classList.remove("is-active");
        }
    }
}

const appDropdownBtn = document.getElementById('app-dropdown-btn');
appDropdownBtn?.addEventListener("click", ()=>{
    const container = appDropdownBtn.parentNode?.parentNode as HTMLDivElement;
    container?.classList.toggle("is-active");
})
