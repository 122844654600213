import { is } from "@babel/types";

let isOpen = false;

const mobileMenuBtn: HTMLElement | null = document.getElementById("mobile-menu");

mobileMenuBtn?.addEventListener("click", (evt:MouseEvent)=>{
    evt.preventDefault();
    toggleMenu();
})

function toggleMenu():void {
    const navbarMenu: HTMLElement | null = document.querySelector(".navbar-menu");
    const navbarBrand: HTMLElement | null = document.querySelector(".navbar-brand");
    if(mobileMenuBtn != null){  
        if(isOpen){
            mobileMenuBtn.classList.remove("is-active");
            navbarMenu?.classList.remove("is-active"); 
            navbarBrand?.classList.remove("is-active"); 
            mobileMenuBtn.ariaExpanded = "false";
        }   
        else{
            mobileMenuBtn.classList.add("is-active");
            navbarMenu?.classList.add("is-active");
            navbarBrand?.classList.add("is-active");
            mobileMenuBtn.ariaExpanded = "true";
        }   
        
        isOpen = !isOpen;
    }
}

function toggleDropdownMobile(evt:MouseEvent):void {
    evt.preventDefault();
    if(window.matchMedia("(max-width: 1023px)")){ 
        const dropdownControl = evt.target as HTMLButtonElement | null;
        const mobileDropdown = dropdownControl?.parentElement?.querySelector(".nav-dropdown-mobile") as HTMLDivElement;
        // const dropdownControl: HTMLElement | null = document.getElementById("schools-link");

        if(mobileDropdown?.classList.contains("show-tablet-below")){
            mobileDropdown?.classList.remove("show-tablet-below"); 
            dropdownControl ? dropdownControl.ariaExpanded = "false" : null;
        }
        else{
            mobileDropdown?.classList.add("show-tablet-below");
            dropdownControl ? dropdownControl.ariaExpanded = "true" : null;
        }
        
    }
}

const schoolsLink: HTMLDivElement | null = document.querySelector("#schools-link");
schoolsLink?.addEventListener("click", toggleDropdownMobile);
const directorsLink: HTMLDivElement | null = document.querySelector("#directors-link");
directorsLink?.addEventListener("click", toggleDropdownMobile);


