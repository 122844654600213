const whoBtn = document.getElementById("who-button");
whoBtn?.addEventListener("click", ()=>{    
    window.location.href = "#who-we-are";
    setTimeout(() => {
        window.scrollBy(0, -45);    
    }, 300);
})

async function signup(evt:SubmitEvent){
    evt.preventDefault();
    const form = evt.target as HTMLFormElement;
    const button = form.querySelector('button[type="submit"]');
    button?.classList.add("is-loading");
    const formData = new FormData(form);
    const name = formData.get('signup-name');
    const email = formData.get('signup-email');
    const response = await fetch("/", {
        method:"POST",
        headers:{"Content-Type": "application/json"},
        body:JSON.stringify({name,email})
    })
    button?.classList.remove("is-loading");
    
    if(response.ok)
    { 
        const success = document.querySelector("#form-success"); 
        success?.classList.remove("hide");
        const textInputs = form.querySelectorAll("input");
        textInputs.forEach((text)=>text.value = "");
    }
    else{
        const fail = document.querySelector("#form-failure");
        fail?.classList.remove('hide');
    }
    
}

const form = document.querySelector('#sign-up-form') as HTMLFormElement;
if(form) form?.addEventListener("submit", signup);