const moreInfoButtons = document.querySelectorAll('.donation-more-info');

moreInfoButtons.forEach((btn)=>{
    btn.addEventListener("click", (evt:Event)=>{
        const infoBtn = evt.target as HTMLButtonElement;
        const modalId = infoBtn.dataset.id;
        const modal = document.querySelector(`#${modalId ?? ""}`);
        modal?.classList.add("is-active");
    })
});

const closeInfoButtons = document.querySelectorAll('.close-info');

closeInfoButtons.forEach((btn)=>{
    btn.addEventListener("click", (evt:Event)=>{
        const closeBtn = evt.target as HTMLButtonElement;
        const modalId = closeBtn.dataset.id;
        const modal = document.querySelector(`#${modalId ?? ""}`);
        modal?.classList.remove("is-active");
    })
})
