export function getDayOfWeek(day:number):string{
    let dayOfMonth = "";
    switch(day){
        case 0:
            dayOfMonth = "Sunday";
            break;
        case 1:
            dayOfMonth = "Monday";
            break;
        case 2:
            dayOfMonth = "Tuesday";
            break;
        case 3:
            dayOfMonth = "Wednesday";
            break;
        case 4:
            dayOfMonth = "Thursday";
            break;
        case 5:
            dayOfMonth = "Friday";
            break;
        case 6:
            dayOfMonth = "Saturday";
            break;
    }
    return dayOfMonth;
}