import {cohortViewModel} from "../../server/types/SchoolViewModelType";
import {start} from "node:repl";
import {getDayOfWeek} from "../util/dateUtils";

let courseMenuActive = false;
let cohortInfoModalActive = true;
const panelControls: NodeListOf<HTMLDivElement> = document.querySelectorAll(".school-panel");

panelControls.forEach((panel: HTMLDivElement, index)=>{
    const header: HTMLButtonElement | null = panel.querySelector(".school-header");
    header?.addEventListener("click", ()=>{
        togglePanel(header);   
    });
})

function togglePanel(panelControl:HTMLHeadElement):void{
    // @ts-ignore
    const panelName = panelControl.attributes['aria-controls'].value;
    const panel: HTMLDivElement | null = document.querySelector(`#${panelName}`);
    const isHidden = panel?.classList.contains("hide");
    if(isHidden){
        panel?.classList.remove("hide");
        const icon = panelControl.querySelector("i");
        icon?.classList.add("fa-angle-up");
        icon?.classList.remove("fa-angle-down");
    }
    else{
        panel?.classList.add("hide");
        const icon = panelControl.querySelector("i");
        icon?.classList.add("fa-angle-down");
        icon?.classList.remove("fa-angle-up");
    }
    
}

function toggleCoursesMenu(evt:Event){
    const courseMenu: Element | null = document.querySelector("#courses-menu");
        if(courseMenuActive){
            courseMenu?.classList.remove("is-active");
        }
        else{
            courseMenu?.classList.add("is-active");
        }
        courseMenuActive = !courseMenuActive;
        evt.stopPropagation();     
}

function filterCohortsByLanguage(evt:Event){
    const filterSelection: HTMLInputElement = evt.target as HTMLInputElement;
    const filterType = filterSelection.dataset.id;

    const locationItems: NodeListOf<HTMLDivElement> = document.querySelectorAll(".school-location-item");
    locationItems.forEach((l)=> {
        l.style.display = "block";
    });
    const isOnline = filterType?.includes("online");
    
    if(filterType != "online-all" && filterType != "in-person-all"){
        locationItems.forEach((l: HTMLDivElement)=>{
            if(isOnline && l.dataset.language?.includes("online")) {
                if(l.dataset.language != filterType){
                    l.style.display = "none";
                }
            }
            else if(!isOnline && l.dataset.language?.includes("in-person")){
                if(l.dataset.language != filterType){
                    l.style.display = "none";
                }
            }
           
        })
    }
}

function filterCohortsByCity(evt:Event) {
    const filterSelection: HTMLInputElement = evt.target as HTMLInputElement;
    const filterCity = filterSelection.dataset.city?.toLowerCase();
    radioCityBtns.forEach((b)=>b.classList.remove("is-primary"));
    filterSelection.classList.add("is-primary");
    const locationItems: NodeListOf<HTMLDivElement> = document.querySelectorAll(".school-location-item");
    locationItems.forEach((l)=> {
        l.style.display = "block";
    });
    if(filterCity != "all") {
        locationItems.forEach((l)=>{
            if(l.dataset.city){
                if(l.dataset.city?.toLowerCase() != filterCity){
                    l.style.display = "none";
                } 
            }
        })
    }
}

function closeCohortInfoModal(){
    const cohortInfoModal = document.getElementById('cohort-info') as HTMLDivElement;
    if(cohortInfoModal)
        cohortInfoModal.classList.remove("is-active");
    cohortInfoModalActive = false;
    window.removeEventListener("hashchange", ()=>{});
}

const radioLanguageBtns = document.querySelectorAll("input[name=cohort-filter]");
radioLanguageBtns.forEach((r)=>{
    r.addEventListener("change", filterCohortsByLanguage);
})

const radioCityBtns = document.querySelectorAll(".city-tag");
radioCityBtns.forEach((r)=>{
    r.addEventListener("click", filterCohortsByCity);
})
const menuControl: HTMLDivElement | null = document.querySelector("#courses-menu-btn");
menuControl?.addEventListener("click", toggleCoursesMenu);

if(window.location.pathname.includes("schools")) {
    const body = document.querySelector("body");
    body?.addEventListener("click", (evt: Event) => {
        if (courseMenuActive) {
            toggleCoursesMenu(evt);
        }
        if (cohortInfoModalActive) {
            closeCohortInfoModal();
        }
    });

    body?.addEventListener("keydown", (evt: KeyboardEvent) => {
        if (cohortInfoModalActive) {
            if (evt.key == 'Escape') {
                closeCohortInfoModal();
            }
        }
    })

}

const cohortInfoModal = document.getElementById('cohort-info') as HTMLDivElement;
if(cohortInfoModal) cohortInfoModal.addEventListener("click", (evt:Event)=>evt.stopPropagation());
const modalCloseBtn: NodeListOf<HTMLButtonElement> = document.querySelectorAll('.cohort-modal-close');
modalCloseBtn.forEach((btn)=>{
    btn.addEventListener("click", closeCohortInfoModal);    
})


const cohortItems: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".school-location-item");

cohortItems.forEach((item)=>{
    item.addEventListener("click", async ()=>{
        window.location.hash="cohortInfo";
        const schoolId = item.dataset.schoolid;
        const courseId = item.dataset.courseid;
        const cohortId = item.dataset.id;
        // console.log(schoolId, courseId, cohortId);
        const response = await fetch(`/schools/api/cohort-detail/${schoolId}/${courseId}/${cohortId}`);
        const data:cohortViewModel = await response.json();
        const cohortModal = document.getElementById("cohort-info");
        if(cohortModal) {
            cohortModal.getElementsByClassName("cohort-name")[0].innerHTML = data.name;
            cohortModal.getElementsByClassName("cohort-notes")[0].innerHTML = data.notes ?? "";
            const instructors = cohortModal.getElementsByClassName("instructor")[0];
            const list = data.instructors.map((ins)=>{
                return `<p>${ins.name}</p>`;
            })
            instructors.innerHTML = `
                <h5>Instructors</h5>
                ${list.join("")}
            `;
            const dates = cohortModal.querySelector(".dates");
            const dateItems = data.datesDisplay.map((d, index)=>{
                let location = "";
                if(window.location.pathname.toLowerCase().includes("spiritual-direction") && 
                    (window.location.pathname.toLowerCase().includes("year-1") || window.location.pathname.toLowerCase().includes("year-2"))){
                    if(data.locationType == 'Hybrid'){
                        location = index == 0 || index == 4 ? " - (In Person)" : " - (Online)"    
                    }
                        
                }
                
                return `<li>${d} ${location}</li>`;
            })
            const listView = dates?.querySelector("ul");
            if(listView)
                listView.innerHTML = dateItems.join("");   
            const actionsView = document.querySelector(".actions") as HTMLDivElement;
            if(actionsView && !data.isOpen)
                actionsView.style.display = "none";
            if(actionsView) {
                const anchorElement = actionsView.querySelector("a") as HTMLAnchorElement;
                anchorElement.href = data.registrationUrl;
            }
            
            if(data.datesDisplay.length == 0){
                const dateElement = document.querySelector("#cohort-info .dates");
                if(dateElement) dateElement.classList.add("hide");
            }
            else{
                const dateElement = document.querySelector("#cohort-info .dates");
                if(dateElement) dateElement.classList.remove("hide");
            }
            //NOTES
            if(window.location.pathname.toLowerCase().includes("spiritual-direction")){
                const daysHtml = document.querySelector(".days") as HTMLDivElement;
                let weekOfMonth = "";
                if(data.dates.length > 0){
                    const dateTest = data.dates[0];
                    if(new Date(dateTest.start).getTime() == new Date(dateTest.end).getTime()){
                        const dayNumber = new Date(dateTest.end).getUTCDay();
                        const dateNumber = new Date(dateTest.start).getUTCDate();
                        const dayDateTotal = dayNumber + dateNumber;
                        if(dayDateTotal < 13) {weekOfMonth = "1st" }
                        else if(dayDateTotal < 20) { weekOfMonth = "2nd" }
                        else if(dayDateTotal < 27) {weekOfMonth = "3rd"}
                        else if(dayDateTotal < 34) {weekOfMonth = "4th"}
                        
                        const meetingDay = `Cohort takes place the ${weekOfMonth} ${getDayOfWeek(dayNumber)} of each month`;
                        if(daysHtml) daysHtml.innerHTML = meetingDay;
                    }else{
                        //show days only
                        const startDay = new Date(dateTest.start).getUTCDay();
                        const endDay = new Date(dateTest.end).getUTCDay();
                        const meetingDays = `Cohort takes place ${getDayOfWeek(startDay)}-${getDayOfWeek(endDay)}`;
                        if(daysHtml) daysHtml.innerHTML = meetingDays;
                    }
                    
                }
                else{
                    daysHtml.innerHTML = "";
                }
                const timezoneHtml = document.querySelector(".timezone");
                if(data.locationType == 'Online') {
                    if(data.timezone){
                        const timezone = `Cohort meets in the US ${data.timezone} timezone`;
                        if(timezoneHtml) timezoneHtml.innerHTML = timezone;    
                    }
                    
                }
                else {
                    if(timezoneHtml) timezoneHtml.innerHTML = "";
                }
            }
            
            cohortModal.classList.add("is-active");
        }
        
        window.addEventListener("hashchange", (evt)=>{
            closeCohortInfoModal();
            
        })
        
    })
})