const bios: string[] = [];
const teacherLinks = document.querySelectorAll(".teacher-item-btn");
let currentBio: string;

function showBio(id:string){
    
    const bioView = document.getElementById("bio-view");
    const userBox = document.getElementById(id);
    const btnEle = userBox?.querySelector("button");
    const imageElement = userBox?.querySelector("img");
    const inputElement = userBox?.querySelector("input");
    const viewName = bioView?.querySelector("h3");
    const viewImage = bioView?.querySelector("img");
    const viewBio = bioView?.querySelector("p");
    if(viewName)
        viewName.innerHTML = btnEle?.innerHTML ?? "";
    if(viewImage)
        viewImage.src = imageElement?.src ?? "";
    if(viewBio)
        viewBio.innerHTML = inputElement?.value ?? "";
    bioView?.classList.add('is-active');
    
    currentBio = id;
    
}

function getBio(evt:Event){
    const btnElement = evt.target as HTMLButtonElement;
    showBio(btnElement?.dataset.id ?? "1")
}

teacherLinks.forEach((t)=>{
    const btn = t as HTMLButtonElement;
    t.addEventListener("click", getBio);
    bios.push(btn?.dataset.id ?? "");
});

document.getElementById("modal-close")?.addEventListener("click", 
    ()=> document.getElementById("bio-view")?.classList.remove("is-active"));

function nextBio(){
    for(let i = 0; i < bios.length; i++){
        if(currentBio == bios[bios.length - 1]){
            showBio(bios[0]);
            break;
        }
        if(currentBio == bios[i]){
            showBio(bios[i + 1]);
            break;
        }
    }
}

function previousBio() {
    for(let i = 0; i < bios.length; i++){
        if(currentBio == bios[0]){
            showBio(bios[bios.length - 1]);
            break;
        }
        if(currentBio == bios[i]){
            showBio(bios[i - 1]);
            break;
        }
    }
}
document.getElementById('next')?.addEventListener("click", nextBio);
document.getElementById('previous')?.addEventListener("click", previousBio);

document.addEventListener("keydown", navigateBio);

function navigateBio(evt:KeyboardEvent){
    const bioView = document.getElementById('bio-view');
    if(bioView?.classList.contains("is-active")){
        if(evt.code === "ArrowRight") {
            nextBio();
        }
        else if(evt.code == "ArrowLeft"){
            previousBio();
        }
        else if(evt.code === "Escape"){
            bioView.classList.remove("is-active");
        }
    }
}